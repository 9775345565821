/* khmer */
@font-face {
  font-family: 'Battambang';
  font-style: normal;
  font-weight: 400;
  src: local('Battambang'), url(../fonts/battambang.woff2) format('woff2');
  unicode-range: U+1780-17FF, U+200B-200C, U+25CC;
}
/* khmer */
@font-face {
  font-family: 'Battambang';
  font-style: normal;
  font-weight: 700;
  src: local('Battambang Bold'), local('Battambang-Bold'), url(../fonts/battambang-bold.woff2) format('woff2');
  unicode-range: U+1780-17FF, U+200B-200C, U+25CC;
}
/* khmer */
@font-face {
  font-family: 'Koulen';
  font-style: normal;
  font-weight: 400;
  src: local('Koulen'), url(../fonts/koulen.woff2) format('woff2');
  unicode-range: U+1780-17FF, U+200B-200C, U+25CC;
}

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(../fonts/fa-brands-400.eot);
  src: url(../fonts/fa-brands-400.eot?#iefix) format("embedded-opentype"),
	url(../fonts/fa-brands-400.woff2) format("woff2"),
	url(../fonts/fa-brands-400.woff) format("woff"),
	url(../fonts/fa-brands-400.ttf) format("truetype"),
	url(../fonts/fa-brands-400.svg#fontawesome) format("svg");
}

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url(../fonts/fa-solid-900.eot);
  src: url(../fonts/fa-solid-900.eot?#iefix) format("embedded-opentype"),
	url(../fonts/fa-solid-900.woff2) format("woff2"),
	url(../fonts/fa-solid-900.woff) format("woff"),
	url(../fonts/fa-solid-900.ttf) format("truetype"),
	url(../fonts/fa-solid-900.svg#fontawesome) format("svg");
}
